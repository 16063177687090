/*
 *  colourBrightness.js
 *
 *  Copyright 2013-2016, Jamie Brittain - http://jamiebrittain.com
 *  Released under the WTFPL license
 *  http://sam.zoy.org/wtfpl/
 *
 *  Github:  http://github.com/jamiebrittain/colourBrightness.js
 *  Version: 1.2
 */
!(function (r) {
  r.fn.colourBrightness = function () {
    function r(r) {
      for (
        var t = '';
        'html' != r[0].tagName.toLowerCase() &&
        ((t = r.css('background-color')),
        'rgba(0, 0, 0, 0)' == t || 'transparent' == t);

      )
        r = r.parent();
      return t;
    }
    var t,
      a,
      s,
      e,
      n = r(this);
    return (
      n.match(/^rgb/)
        ? ((n = n.match(/rgba?\(([^)]+)\)/)[1]),
          (n = n.split(/ *, */).map(Number)),
          (t = n[0]),
          (a = n[1]),
          (s = n[2]))
        : '#' == n[0] && 7 == n.length
        ? ((t = parseInt(n.slice(1, 3), 16)),
          (a = parseInt(n.slice(3, 5), 16)),
          (s = parseInt(n.slice(5, 7), 16)))
        : '#' == n[0] &&
          4 == n.length &&
          ((t = parseInt(n[1] + n[1], 16)),
          (a = parseInt(n[2] + n[2], 16)),
          (s = parseInt(n[3] + n[3], 16))),
      (e = (299 * t + 587 * a + 114 * s) / 1e3),
      125 > e
        ? this.removeClass('light').addClass('dark')
        : this.removeClass('dark').addClass('light'),
      this
    );
  };
})(jQuery);
